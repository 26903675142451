import { Fragment, useMemo, useContext } from 'react';
import { Router } from '@reach/router';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import CveList from '../../components/vulnerabilities/cve-list/CveList';
import CveDetails from '../../components/vulnerabilities/cve-details/CveDetails';
import { useTranslation } from 'react-i18next';
import { NotificationContext, UserContext } from '@mdc/ui';
import { i18nUtility } from '@mdc/services';

import './Vulnerabilities.scss';

const Vulnerabilities = () => {
    const { ready } = useTranslation();
    const userContext = useContext(UserContext);
    const authIsLoading = userContext.state === userContext.STATES.PENDING;
    const { notify } = useContext(NotificationContext);

    const PageFragment = useMemo(() => {
        if (!ready || authIsLoading) {
            return null;
        }

        return (
            <Router className='vulnerabilitiesPage'>
                {i18nUtility.pathList.map((path) => <Fragment key={path}>
                    <CveList path={`${path}/vulnerabilities/`} notify={notify}/>
                    <CveDetails path={`${path}/vulnerabilities/:cve`} notify={notify}/>
                </Fragment>)}
            </Router>);
    }, [authIsLoading, ready]);

    return (
        <Layout pageTitle='Vulnerabilities' sitemapURL='/CVE.sitemap.xml' pageDescription={'A list of supported CVE Vulnerabilities'}>
            {PageFragment}
        </Layout>
    );
};

export default Vulnerabilities;
